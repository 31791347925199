import React from 'react'
import {
  BookOutlined,
  CompassOutlined,
  ControlOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  FundViewOutlined,
  MailOutlined,
  NotificationOutlined,
  PlusCircleOutlined,
  ProjectOutlined,
  RestOutlined,
  SmileOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { Badge } from 'src/antd'
import styled from 'styled-components'

import { TSidebarItem, TSidebarOriginalItem } from 'src/components/layout/types'
import { routingMap } from 'src/reducers/routing/routing-map'
import { Role } from 'src/reducers/routing/utils'
import { navy900 } from 'src/styles/color-theme'

import { isChildrenSelected, isItemSelected } from './isItemSelected'
import ProjectsSelect from '../ProjectsSelect'

export const SELECTED_CLASS = 'isSelected'

export const SELECTED_SUBMENU_CLASS = 'is_submenu_selected'

const StyledBadge = styled(Badge)`
  .ant-scroll-number-only > .ant-scroll-number-only-unit {
    line-height: 20px;
  }
`

export default function getItems(
  t: (str: string, props?: Record<string, unknown>) => string,
  opened: boolean,
  selectedProjects: number,
  companyId: string,
  roles: Role[],
  pathname: string,
  onItemClick: (item: TSidebarItem) => void,
  onLinkClick: (pathname: string, state?: Record<string, unknown>) => void
): ItemType[] {
  const items: TSidebarOriginalItem[] = [
    {
      key: 'projects',
      label:
        selectedProjects === 1
          ? t('{count} project selected', { count: 1 })
          : t('{count} projects selected', { count: selectedProjects }),
      icon: (
        <StyledBadge count={selectedProjects} color={navy900}>
          <BookOutlined />
        </StyledBadge>
      ),
      onClick: () =>
        onItemClick({ key: 'projects', component: ProjectsSelect }),
    },
    { type: 'divider' as const, style: { margin: '16px 0' } },
    {
      key: 'dashboard',
      label: t('Dashboard'),
      icon: <DashboardOutlined />,
      children: [
        {
          title: t('Dashboard'),
          children: [
            {
              title: t('Main dashboard'),
              icon: <DashboardOutlined />,
              link: routingMap.app.dashboard._,
              visibleInTooltip: true,
            },
            {
              title: t('Dashboards'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app.analytics.dashboards._,
              skipLinks: [routingMap.app.analytics.dashboards.create._],
              visibleInTooltip: true,
            },
            {
              title: t('New dashboard'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app.analytics.dashboards.create._,
            },
          ],
        },
        {
          title: t('Exports'),
          children: [
            {
              title: t('Exports'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app.analytics.exports._,
              visibleInTooltip: true,
            },
            {
              title: t('Add export'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app.analytics.exports._,
              skipLinks: [routingMap.app.analytics.exports._],
              linkState: {
                modalOpened: true,
              },
            },
            {
              title: t('Classic Exports'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app.analytics['classic-exports']._,
              visibleInTooltip: true,
            },
            {
              title: t('Add classic export'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app.analytics['classic-exports']._,
              skipLinks: [routingMap.app.analytics['classic-exports']._],
              linkState: {
                modalOpened: true,
              },
            },
          ],
        },
        {
          title: t('Imports'),
          children: [
            {
              title: t('Imports'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app.analytics.imports._,
              visibleInTooltip: true,
            },
          ],
        },
      ],
    },
    {
      key: 'asset_management',
      label: t('Asset Management'),
      icon: <RestOutlined />,
      children: [
        {
          title: t('Containers'),
          children: [
            {
              title: t('Containers'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['asset-management'].containers._,
              skipLinks: [
                routingMap.app['asset-management'].containers.map._,
                routingMap.app['asset-management'].containers.create._,
              ],
              visibleInTooltip: true,
            },
            {
              title: t('Containers map'),
              icon: <EnvironmentOutlined />,
              link: routingMap.app['asset-management'].containers.map._,
              skipLinks: [
                routingMap.app['asset-management'].containers.create._,
              ],
              visibleInTooltip: true,
            },
            {
              title: t('Add container'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['asset-management'].containers.create._,
            },
          ],
        },
        {
          title: t('Container groups'),
          children: [
            {
              title: t('Containers groups'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['asset-management']['container-group']._,
              skipLinks: [
                routingMap.app['asset-management']['container-group'].create._,
              ],
              visibleInTooltip: true,
            },
            {
              title: t('Add container group'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['asset-management']['container-group'].create
                ._,
            },
          ],
        },
        {
          title: t('Settings'),
          defaultCollapsed: true,
          children: [
            {
              title: t('Container types'),
              link: routingMap.app.settings['company/:id'](companyId)[
                'asset-management'
              ]['container-types']._,
            },
            {
              title: t('Waste fractions'),
              link: routingMap.app.settings['company/:id'](companyId)[
                'asset-management'
              ]['waste-fractions']._,
            },
          ],
        },
      ],
    },
    {
      key: 'operation_management',
      label: t('Operation Management'),
      icon: <ControlOutlined />,
      children: [
        {
          title: t('Routes'),
          children: [
            ...(roles.includes('wastehero-staff')
              ? [
                  {
                    title: t('Live monitoring'),
                    icon: <FundViewOutlined />,
                    link: routingMap.app['operation-management'][
                      'live-monitoring'
                    ]._,
                    visibleInTooltip: true,
                  },
                ]
              : []),
            {
              title: t('Routes'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['operation-management'].routes._,
              visibleInTooltip: true,
            },
            {
              title: t('Add route'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['operation-management'].routes._,
              skipLinks: [routingMap.app['operation-management'].routes._],
              linkState: { modalOpened: true },
            },
          ],
        },
        {
          title: t('Route scheme'),
          children: [
            {
              title: t('Route schemes'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['operation-management']['route-schemes']._,
              skipLinks: [
                routingMap.app['operation-management']['route-schemes'].create
                  ._,
              ],
              visibleInTooltip: true,
            },
            {
              title: t('Add route scheme'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['operation-management']['route-schemes']
                .create._,
            },
          ],
        },
        {
          title: t('Pickup history'),
          children: [
            {
              title: t('Pickup history'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['operation-management']['pickup-history']._,
              visibleInTooltip: true,
            },
          ],
        },
        {
          title: t('Weight control'),
          children: [
            {
              title: t('Weight control'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['operation-management']['weight-control']._,
              visibleInTooltip: true,
            },
          ],
        },
        {
          title: t('Settings'),
          defaultCollapsed: true,
          children: [
            {
              title: t('Pickup settings'),
              link: routingMap.app.settings['company/:id'](companyId)[
                'operation-management'
              ]['pickup-settings']._,
            },
            {
              title: t('Collection calendar days'),
              link: routingMap.app.settings['company/:id'](companyId)[
                'operation-management'
              ]['collection-calendar-days']._,
            },
            {
              title: t('Areas'),
              link: routingMap.app.settings['company/:id'](companyId)[
                'operation-management'
              ].areas._,
            },
            {
              title: t('Haulers'),
              link: routingMap.app.settings['company/:id'](companyId)[
                'operation-management'
              ].haulers._,
            },
          ],
        },
      ],
    },
    {
      key: 'fleet_management',
      label: t('Fleet Management'),
      icon: <CompassOutlined />,
      children: [
        {
          title: t('Vehicles'),
          children: [
            {
              title: t('Vehicles'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['fleet-management'].vehicles._,
              skipLinks: [routingMap.app['fleet-management'].vehicles.create._],
              visibleInTooltip: true,
            },
            {
              title: t('Add vehicle'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['fleet-management'].vehicles.create._,
            },
          ],
        },
        {
          title: t('Drivers'),
          children: [
            {
              title: t('Drivers'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['fleet-management'].drivers._,
              skipLinks: [routingMap.app['fleet-management'].drivers.create._],
              visibleInTooltip: true,
            },
            {
              title: t('Add driver'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['fleet-management'].drivers.create._,
            },
          ],
        },
        {
          title: t('Locations'),
          children: [
            {
              title: t('Locations'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['fleet-management'].locations._,
              skipLinks: [
                routingMap.app['fleet-management'].locations.create._,
              ],
              visibleInTooltip: true,
            },
            {
              title: t('Add location'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['fleet-management'].locations.create._,
            },
          ],
        },
        {
          title: t('Vehicle planner'),
          children: [
            {
              title: t('Vehicle plan list'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['fleet-management']['vehicle-planner']._,
              visibleInTooltip: true,
            },
            {
              title: t('Add vehicle plan'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['fleet-management']['vehicle-planner']._,
              skipLinks: [
                routingMap.app['fleet-management']['vehicle-planner']._,
              ],
              linkState: { modalOpened: true },
            },
          ],
        },
        {
          title: t('Settings'),
          defaultCollapsed: true,
          children: [
            {
              title: t('Vehicle types'),
              link: routingMap.app.settings['company/:id'](companyId)[
                'fleet-management'
              ]['vehicle-types']._,
            },
            {
              title: t('Integrations'),
              link: routingMap.app.settings['company/:id'](companyId)[
                'fleet-management'
              ]['gps-integrations']._,
            },
            {
              title: t('Integration vehicles'),
              link: routingMap.app.settings['company/:id'](companyId)[
                'fleet-management'
              ]['gps-integration-vehicles']._,
            },
          ],
        },
      ],
    },
    {
      key: 'customer_management',
      label: t('Customer Management'),
      icon: <SmileOutlined />,
      children: [
        {
          title: t('Properties'),
          children: [
            {
              title: t('Properties'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['customer-management'].properties._,
              skipLinks: [
                routingMap.app['customer-management'].properties.map._,
                routingMap.app['customer-management'].properties.create._,
              ],
              visibleInTooltip: true,
            },
            {
              title: t('Properties map'),
              icon: <EnvironmentOutlined />,
              link: routingMap.app['customer-management'].properties.map._,
              skipLinks: [
                routingMap.app['customer-management'].properties.create._,
              ],
              visibleInTooltip: true,
            },
            {
              title: t('Add property'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['customer-management'].properties.create._,
            },
          ],
        },
        {
          title: t('Property groups'),
          children: [
            {
              title: t('Property groups'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app['customer-management']['property-groups']._,
              skipLinks: [
                routingMap.app['customer-management']['property-groups'].create
                  ._,
              ],
              visibleInTooltip: true,
            },
            {
              title: t('Add property group'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app['customer-management']['property-groups']
                .create._,
            },
          ],
        },
        {
          title: t('Settings'),
          defaultCollapsed: true,
          children: [
            {
              title: t('Property types'),
              link: routingMap.app.settings['company/:id'](companyId).crm[
                'property-type'
              ]._,
            },
            {
              title: t('Container pricing'),
              link: routingMap.app.settings['company/:id'](companyId).crm
                .pricing.containers._,
            },
            {
              title: t('Recurring pricing'),
              link: routingMap.app.settings['company/:id'](companyId).crm
                .pricing.recurring._,
            },
            {
              title: t('Services pricing'),
              link: routingMap.app.settings['company/:id'](companyId).crm
                .pricing.services._,
            },
            {
              title: t('Scheduled exports'),
              link: routingMap.app.settings['company/:id'](companyId).crm[
                'scheduled-exports'
              ]._,
            },
          ],
        },
      ],
    },
    {
      key: 'tickets',
      label: t('Tickets'),
      icon: <ProjectOutlined />,
      children: [
        {
          title: t('Tickets'),
          children: [
            {
              title: t('Tickets'),
              icon: <UnorderedListOutlined />,
              link: routingMap.app.tickets._,
              skipLinks: [
                routingMap.app.tickets.kanban._,
                routingMap.app.tickets.map._,
                routingMap.app.tickets['create-bulk'].containers._,
                routingMap.app.tickets['create-bulk'].properties._,
                routingMap.app.tickets['customer-inbox']._,
              ],
              visibleInTooltip: true,
            },
            {
              title: t('Ticket map'),
              icon: <EnvironmentOutlined />,
              link: routingMap.app.tickets.map._,
              visibleInTooltip: true,
            },
            {
              title: t('Ticket kanban'),
              icon: <ProjectOutlined />,
              link: routingMap.app.tickets.kanban._,
              visibleInTooltip: true,
            },
            {
              title: t('Create container tickets'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app.tickets['create-bulk'].containers._,
            },
            {
              title: t('Create property tickets'),
              icon: <PlusCircleOutlined />,
              link: routingMap.app.tickets['create-bulk'].properties._,
            },
          ],
        },
        {
          title: t('Customer inbox'),
          children: [
            {
              title: t('Customer inbox'),
              icon: <MailOutlined />,
              link: routingMap.app.tickets['customer-inbox']._,
              visibleInTooltip: true,
            },
          ],
        },
        {
          title: t('Settings'),
          defaultCollapsed: true,
          children: [
            {
              title: t('Ticket type'),
              link: routingMap.app.settings['company/:id'](companyId).tickets[
                'ticket-types'
              ]._,
            },
            {
              title: t('Ticket category'),
              link: routingMap.app.settings['company/:id'](companyId).tickets[
                'ticket-category'
              ]._,
            },
            {
              title: t('Order customisation'),
              link: routingMap.app.settings['company/:id'](companyId).tickets[
                'order-customisation'
              ]._,
            },
            {
              title: t('Services'),
              link: routingMap.app.settings['company/:id'](companyId).crm
                .services._,
            },
          ],
        },
      ],
    },
    {
      key: 'notifications',
      label: t('Alerts'),
      icon: <NotificationOutlined />,
      link: routingMap.app.notifications.alerts._,
      onClick: () => onLinkClick(routingMap.app.notifications.alerts._),
    },
  ]

  return items.map((item) => {
    if ('type' in item) {
      return item
    }

    const { children, onClick, ...rest } = item
    const itemChildren = opened
      ? undefined
      : children?.reduce<ItemType[]>((arr, child) => {
          child.children.forEach((subChild) => {
            if (subChild.visibleInTooltip) {
              arr.push({
                key: subChild.link,
                label: subChild.title,
                icon: subChild.icon,
                className: isChildrenSelected(pathname, subChild)
                  ? SELECTED_SUBMENU_CLASS
                  : '',
                onClick: () => onLinkClick(subChild.link, subChild.linkState),
              })
            }
          })
          return arr
        }, [])

    const handleClick = () => {
      if (onClick) {
        onClick()
        return
      }

      if (!children) {
        return
      }

      onItemClick({
        key: item.key,
        title: item.label || '',
        icon: item.icon,
        children: children.map((child) => ({
          ...child,
          children: child.children.map((subChild) => {
            const { link, linkState, visibleInTooltip, ...restSub } = subChild
            return {
              ...restSub,
              isSelected: isChildrenSelected(pathname, subChild),
              onClick: () => onLinkClick(link, linkState),
            }
          }),
        })),
      })
    }

    return {
      ...rest,
      className: isItemSelected(pathname, item) ? SELECTED_CLASS : '',
      children: itemChildren?.length
        ? [
            { key: `${item.key}-group`, label: item.label, type: 'group' },
            ...itemChildren,
            {
              key: `${item.key}-advance`,
              label: t('Open advance menu'),
              onClick: handleClick,
            },
          ]
        : undefined,
      ...(itemChildren?.length
        ? { onTitleClick: handleClick }
        : { onClick: handleClick }),
      popupClassName: 'custom-submenu',
      popupOffset: [16, 0],
    }
  })
}
