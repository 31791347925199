import { gql } from '@apollo/client'

export const GET = gql`
  query GetPropertyUserInfoPopover($id: ID!) {
    propertyUser(id: $id) {
      id
      name
      username
      phoneNumber
      email
    }
  }
`
