import React from 'react'
import { SmileOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Row } from 'src/antd'
import styled from 'styled-components'

import ControlBarButton from './ControlBarButton'
import { TX_EMOJI } from '../../../transifex'
import { CssScroller } from '../../CssScroller'
import { emojis } from '../config'

const StyledButton = styled(Button)`
  border: none;
  font-size: 16px;
  box-shadow: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

const StyledRow = styled(Row)`
  padding: 8px;
  width: 301px;
  max-height: 150px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 12px 0 rgb(0 0 0 / 6%),
    0 9px 16px 8px rgb(0 0 0 / 3%);
  ${CssScroller}
  padding-right: 4px;
  overflow-y: scroll;
`

const EmojiSelector = ({
  onEmojiSelect,
}: {
  onEmojiSelect?: (emoji: string) => void
}) => {
  return (
    <StyledRow gutter={[4, 2]}>
      {emojis.map((emoji) => (
        <Col key={emoji} span={3}>
          <StyledButton size="small" onClick={() => onEmojiSelect?.(emoji)}>
            {emoji}
          </StyledButton>
        </Col>
      ))}
    </StyledRow>
  )
}

const Emoji = ({
  onEmojiSelect,
}: {
  onEmojiSelect?: (emoji: string) => void
}) => {
  return (
    <Dropdown
      placement="bottom"
      trigger={['click']}
      dropdownRender={() => <EmojiSelector onEmojiSelect={onEmojiSelect} />}
    >
      <ControlBarButton icon={<SmileOutlined />} tooltip={TX_EMOJI} />
    </Dropdown>
  )
}

export default Emoji
