import { pathJoin } from 'src/reducers/routing/utils'

import { createMetaValueRoutesMap } from './crm/meta-values'

export const createCustomFieldsRoutesMap = (prefix: string) => ({
  tickets: pathJoin(prefix)('tickets').chain,
  properties: pathJoin(prefix)('properties').chainMapAuto(
    createMetaValueRoutesMap
  ),
})
