import { gql } from '@apollo/client'

import { FrgWasteFraction } from './waste-fraction'

export const FrgAgreementForTable = gql`
  ${FrgWasteFraction}
  fragment FrgAgreementForTable on AgreementDef {
    id
    isActive
    isFuture
    isEnded
    company {
      id
    }
    property {
      id
    }
    quantity
    product {
      id
      containerType {
        id
        name
      }
      wasteFraction {
        ...FrgWasteFraction
        wasteSubstance
        wasteCategory
      }
      pickupSetting {
        id
        name
      }
    }
    isSplit
    myShare
    startDate
    endDate
    billingPrice
    recurringPrice {
      id
      name
      recurringInterval
      amount
    }
    container {
      id
      containerId
      metaData
      location {
        id
        latitude
        longitude
        name
      }
      measurement {
        id
        fillPercentage
      }
      wasteFraction {
        ...FrgWasteFraction
        wasteSubstance
        wasteCategory
      }
      containerType {
        id
        name
      }
      pickupSetting {
        id
        name
      }
      latestPickUp
      nextPickUp
      collectionCalendar {
        id
        name
      }
      routeScheme {
        id
        name
      }
      tickets {
        edges {
          node {
            id
            status
            ticketType {
              service {
                serviceType
              }
            }
            container: managecontainerticket {
              containerType {
                id
                name
              }
              pickupSetting {
                id
                name
              }
              collectionCalendar {
                id
                name
              }
              routeScheme {
                id
                name
              }
            }
          }
        }
      }
    }
    prices {
      changeContainerType {
        price {
          id
          product {
            id
          }
          amount
          recurringInterval
        }
        containerType {
          id
          name
        }
      }
      changePickupSetting {
        price {
          id
          product {
            id
          }
          amount
          recurringInterval
        }
        pickupSetting {
          id
          name
        }
      }
    }
  }
`
