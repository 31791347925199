/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode, useState } from 'react'
import { InsertRowRightOutlined, MenuOutlined } from '@ant-design/icons'
import { T } from '@transifex/react'
import { uniq } from 'lodash'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import { Checkbox, Space } from 'src/antd'
import useLocalStorageState from 'use-local-storage-state'

import { FilterMenuButton } from 'src/new-components/filter-menu-button'
import { divMain } from 'src/styles/color-theme'

import {
  StyledPlaceholderWrapper,
  StyledPopover,
  UseTreeFilterAndOrderBaseArgs,
  UseTreeFilterAndOrderReturn,
  reorder,
} from './shared'
import { TX_DISPLAY_COLUMNS } from '../../transifex'
import { Typography } from '../Typography'

export type { UseTreeFilterAndOrderReturn } from './shared'

export type TColumnOrderSectionType = 'staticColumns' | 'otherColumns'

function useConditionalState<T>(
  key: string,
  options: {
    defaultValue: T
    isPersistent?: boolean
  }
) {
  if (options.isPersistent) {
    const [value, setValue] = useLocalStorageState(key, {
      defaultValue: options.defaultValue,
    })

    return [value, setValue] as const
  }

  return useState<T>(options.defaultValue)
}

const DragPlaceholder = ({
  type,
  isDraggingOver,
}: {
  type: ReactNode
  isDraggingOver: boolean
}) => (
  <StyledPlaceholderWrapper $isDraggingOver={isDraggingOver}>
    <div>
      <T
        _str="Drag a column here to make it {type} in the table."
        type={type}
      />
    </div>
  </StyledPlaceholderWrapper>
)

const generateColumnData = <T extends string>(keyName: T) => {
  return keyName
}

export type UseTreeFilterAndOrderArgs<T extends string> =
  UseTreeFilterAndOrderBaseArgs<T> & {
    key: string
    isPersistent?: boolean
  }

export const useTreeFilterAndOrder = <T extends string>({
  init,
  key,
  popover,
  hideColumns = [],
  hideTreeColumns,
  columns,
  defaultFixedColumns,
  isPersistent,
}: UseTreeFilterAndOrderArgs<T>): UseTreeFilterAndOrderReturn<T> => {
  const hiddenColumns = hideTreeColumns || hideColumns

  const defaultColumnOrdering = {
    staticColumns: (defaultFixedColumns || [])
      .filter((i) => !hiddenColumns?.includes(i))
      .map(generateColumnData),
    otherColumns: init.order
      .filter(
        (keyName) =>
          !defaultFixedColumns?.includes(keyName) &&
          !hiddenColumns?.includes(keyName)
      )
      .map(generateColumnData),
  }

  const [checkedCols, setCheckedCols] = useConditionalState(
    `useTreeFilterAndOrder.check.${key}`,
    { defaultValue: init.checked, isPersistent }
  )

  const [persistedCols, setCols] = useConditionalState(
    `useTreeFilterAndOrder.columns.${key}`,
    { defaultValue: defaultColumnOrdering, isPersistent }
  )

  const definedPersistedStaticColumns = persistedCols.staticColumns.filter(
    (c) => columns[c]
  )

  const definedPersistedOtherColumns = persistedCols.otherColumns.filter(
    (c) => columns[c]
  )

  const cols = {
    staticColumns: uniq(definedPersistedStaticColumns),
    otherColumns: uniq(definedPersistedOtherColumns),
  }

  const columnsWithLabel = {
    staticColumns: cols.staticColumns.map((c) => {
      const { title } = columns[c] || {}
      return { id: c, content: title }
    }),
    otherColumns: cols.otherColumns.map((c) => {
      const { title } = columns[c] || {}
      return { id: c, content: title }
    }),
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    if (result.source.droppableId === result.destination.droppableId) {
      const items = reorder(
        cols[result.source.droppableId as TColumnOrderSectionType],
        result.source.index,
        result.destination.index
      )

      setCols({
        ...cols,
        [result.source.droppableId]: items,
      })
    } else {
      const sourceItems = Array.from(
        cols[result.source.droppableId as TColumnOrderSectionType]
      )
      const destItems = Array.from(
        cols[result.destination.droppableId as TColumnOrderSectionType]
      )
      const [removed] = sourceItems.splice(result.source.index, 1)
      destItems.splice(result.destination.index, 0, removed)

      setCols({
        ...cols,
        [result.source.droppableId]: sourceItems,
        [result.destination.droppableId]: destItems,
      })
    }
  }

  const handleAllColsSelected = (checked: boolean) => {
    if (checked) {
      setCheckedCols(init.order)
    } else {
      setCheckedCols([])
    }
  }

  const orderedCols = [
    ...(hideTreeColumns || []),
    ...cols.staticColumns,
    ...cols.otherColumns,
  ]

  return {
    state: orderedCols.filter((k) => checkedCols.includes(k)),
    fixedColumns: cols.staticColumns,
    jsx: {
      toggler: (
        <StyledPopover
          {...(popover || {})}
          autoAdjustOverflow={false}
          placement={popover?.placement || 'bottomRight'}
          title={
            <Checkbox
              onChange={(e) => handleAllColsSelected(e.target.checked)}
              indeterminate={
                !!checkedCols.length && checkedCols.length !== init.order.length
              }
              checked={checkedCols.length === init.order.length}
            >
              {TX_DISPLAY_COLUMNS}
            </Checkbox>
          }
          content={
            <div
              style={{
                width: 250,
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                {Object.entries(columnsWithLabel).map(([k, items], ind) => (
                  <Droppable key={k} droppableId={k}>
                    {(provided, snapshot) => (
                      <>
                        <Typography
                          weight="semi-bold"
                          style={{
                            marginBottom: 12,
                            marginTop: ind ? 12 : 0,
                          }}
                        >
                          {k === 'staticColumns'
                            ? 'Static Columns'
                            : 'Other Columns'}
                        </Typography>
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {!items?.length && !snapshot.isDraggingOver && (
                            <DragPlaceholder
                              isDraggingOver={snapshot.isDraggingOver}
                              type={
                                k === 'staticColumns' ? (
                                  <T _str="static" />
                                ) : (
                                  <T _str="dynamic" />
                                )
                              }
                            />
                          )}
                          {items.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(prvd) => (
                                <div
                                  ref={prvd.innerRef}
                                  {...prvd.draggableProps}
                                  style={prvd.draggableProps.style}
                                >
                                  <Space
                                    style={{ width: '100%', margin: '4px 0' }}
                                    size={8}
                                  >
                                    <span {...prvd.dragHandleProps}>
                                      <MenuOutlined
                                        style={{ fontSize: 16, color: divMain }}
                                      />
                                    </span>
                                    <Checkbox
                                      checked={checkedCols.includes(item.id)}
                                      onChange={(e) => {
                                        const newCheckedCols = e.target.checked
                                          ? uniq([...checkedCols, item.id])
                                          : checkedCols.filter(
                                              (col) => col !== item.id
                                            )
                                        setCheckedCols(newCheckedCols)
                                      }}
                                    />
                                    {item.content}
                                  </Space>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      </>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
            </div>
          }
        >
          <FilterMenuButton withCaretIcon icon={<InsertRowRightOutlined />} />
        </StyledPopover>
      ),
    },
  }
}
