import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { EventTicketEventEventTypeChoices } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const eventTicketEventEventTypeChoicesLabelMap: Record<
  EventTicketEventEventTypeChoices,
  ReactNode
> = {
  A_1: <T _str="Change Status" />,
  A_2: <T _str="Change Assignee" />,
  A_3: <T _str="Change Priority" />,
  A_4: <T _str="Change Assigned Team" />,
  A_10: <T _str="Updated ticket details" />,
  A_11: <T _str="Scan barcode" />,
  A_12: <T _str="Print label" />,
  A_13: <T _str="Pair RFID" />,
  A_20: <T _str="Associated message linked" />,
  A_21: <T _str="Associated message unlinked" />,
}
export const ticketEventTypeOptions = createOptionsFromLabelMap(
  eventTicketEventEventTypeChoicesLabelMap
)

export const eventTicketEventTypeChoicesMutationValueMap: Record<
  EventTicketEventEventTypeChoices,
  number
> = {
  A_1: 1,
  A_2: 2,
  A_3: 3,
  A_4: 4,
  A_10: 10,
  A_11: 11,
  A_12: 12,
  A_13: 13,
  A_20: 20,
  A_21: 21,
}
