import { pathJoin } from 'src/reducers/routing/utils'

import { createDataSyncRoutesMap } from './data-sync'
import { createPricingRoutesMap } from './pricing'

export const createCRMRoutesMap = (prefix: string) => ({
  pricing: pathJoin(prefix)('pricing').chainMapAuto(createPricingRoutesMap),
  services: pathJoin(prefix)('services').chainMapAuto((p2) => ({
    ':id': (id = ':id') => pathJoin(p2)(id).chain,
  })),
  'scheduled-exports': pathJoin(prefix)('scheduled-exports').chain,
  'property-type': pathJoin(prefix)('property-type').chain,
  'billing-runs': pathJoin(prefix)('billing-runs').chain,
  'data-sync': pathJoin(prefix)('data-sync').chainMapAuto(
    createDataSyncRoutesMap
  ),
  'service-type': pathJoin(prefix)('service-type').chain,
  integrations: pathJoin(prefix)('integrations').chain,
})
