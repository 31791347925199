import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

export const createCustomerInboxMap = (prefix: string) => ({
  ':inboxId': (inboxId: string) =>
    pathJoin(prefix)(inboxId).chainWithTitle({
      title: <T _str="Inbox Details" />,
      breadCrumbTitle: <T _str="Inbox Details" />,
    }),
})
