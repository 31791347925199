import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createCollectionDeviationDetailViewRoutesMap } from './id'

export const createCollectionDeviationRoutesMap = (prefix: string) => ({
  ':id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(
      createCollectionDeviationDetailViewRoutesMap,
      {
        title: <T _str="Collection Deviation Details" />,
        breadCrumbTitle: <T _str="Collection Deviation Details" />,
      }
    ),
  create: pathJoin(prefix)('create').chainMapAuto((prefix2) => ({
    configuration: pathJoin(prefix2)('configuration').chainWithTitle({
      title: <T _str="Create Collection Deviation" />,
      breadCrumbTitle: <T _str="Create Collection Deviation" />,
    }),
  })),
})
