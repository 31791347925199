import React, { FC } from 'react'
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { t } from '@transifex/native'
import { T } from '@transifex/react'

import useDelayQuery from 'src/hooks/useDelayQuery'
import { array } from 'src/utils/array'

import {
  GetPropertyUserInfoPopover,
  GetPropertyUserInfoPopoverVariables,
} from './__generated__/types'
import { GET } from './api'
import { PopupContentProps } from '..'
import InfoCard from '../components/InfoCard'

const PropertyUserPopup: FC<PopupContentProps> = ({ id }) => {
  const { data: { propertyUser } = {}, loading } = useDelayQuery<
    GetPropertyUserInfoPopover,
    GetPropertyUserInfoPopoverVariables
  >(
    GET,
    {
      variables: {
        id,
      },
    },
    300
  )

  return (
    <InfoCard
      title={propertyUser?.name || t('Citizen')}
      headIcon={<UserOutlined />}
      headText={<T _str="Citizens" />}
      data={array(
        !!propertyUser?.phoneNumber && {
          icon: <PhoneOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />,
          label: propertyUser?.phoneNumber,
        },
        !!propertyUser?.email && {
          icon: <MailOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />,
          label: propertyUser?.email,
        }
      )}
      loading={loading}
    />
  )
}

export default PropertyUserPopup
