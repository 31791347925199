import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createDraftDetailRoutesMap } from './ticket-draft-detail'

export const createTicketDraftsRouteMap = (prefix: string) => ({
  '/:id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(createDraftDetailRoutesMap, {
      breadCrumbTitle: <T _str="Ticket Draft Details" />,
      title: <T _str="Ticket Draft Details" />,
    }),
})
