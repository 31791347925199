import { t } from '@transifex/native'

import { CoreCompanyMetaTypeEntityChoices } from 'src/gen-types'

export const CustomFieldEntityChoicesLabelsMap: Record<
  CoreCompanyMetaTypeEntityChoices,
  string
> = {
  CONTAINER: t('Container'),
  PROPERTY: t('Property'),
  PROPERTY_GROUP: t('Property group'),
}

// const FIELD_ENTITY_COLORS: Record<
//   CoreCompanyMetaTypeEntityChoices,
//   TagProps['color']
// > = {
//   CONTAINER: 'orange',
//   PROPERTY: 'purple',
//   PROPERTY_GROUP: 'pink',
// }
